import './App.css';
import './css/Header.css';
import './css/TopPage.css';
import './css/Page-list.css';
import './css/Footer.css';
import './css/E404.css';
import './css/ArticlePage.css';

import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import HomePage from './components/HomePage';
import E404 from './components/E404';
import ArticlePage from './components/Blog/ArticlePage';

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<Navigate to="/ja-jp/home" replace />} />
          <Route path="/ja-jp/home" element={<HomePage />} />
          <Route path="/ja-jp/home/blog/:id" element={<ArticlePage />} />

          <Route path='*' element={<E404 />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
