import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

function SearchBar() {
    const [searchTerm, setSearchTerm] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [searchCandidates, setSearchCandidates] = useState([]);

    useEffect(() => {
        const fetchArticles = async () => {
            try {
                const response = await fetch(`${process.env.REACT_APP_MINEBLOG_APP_API_ENDPOINT}`, {
                    headers: {
                        'X-API-KEY': process.env.REACT_APP_MINEBLOG_APP_API_KEY,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                const candidates = data.contents.map(content => ({
                    title: content.title,
                    content: content.body,
                    id: content.id, // 仮定のIDフィールド
                }));
                setSearchCandidates(candidates);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            }
        };

        fetchArticles();
    }, []);

    useEffect(() => {
        if (searchTerm) {
            const results = searchCandidates.filter(candidate =>
                candidate.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                candidate.content.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        } else {
            setSearchResults([]);
        }
    }, [searchTerm, searchCandidates]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearchSubmit = (event) => {
        event.preventDefault();
        if (searchTerm) {
            const results = searchCandidates.filter(candidate =>
                candidate.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                candidate.content.toLowerCase().includes(searchTerm.toLowerCase())
            );
            setSearchResults(results);
        }
    };

    return (
        <div className='searchBar'>
            <form className='searchForm' onSubmit={handleSearchSubmit}>
                <input
                    type="search"
                    placeholder="キーワードを入力"
                    value={searchTerm}
                    onChange={handleSearchChange}
                />
            </form>
            {searchTerm && (
                <ul className='searchContents'>
                    {searchResults.length > 0 ? (
                        searchResults.map((result, index) => (
                            <li className='searchContentsLi' key={index}>
                                <Link to={`/ja-jp/home/blog/${result.id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                                    <h3>{result.title}</h3>
                                    <div dangerouslySetInnerHTML={{ __html: result.content.length > 20 ? result.content.substring(0, 20) + '...' : result.content }} />
                                </Link>
                            </li>
                        ))
                    ) : (
                        <li className='CanNotSearch'>該当する記事が見当たりませんでした</li>
                    )}
                </ul>
            )}
        </div>
    );
}

export default SearchBar;
