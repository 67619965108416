import React from 'react';

import Header_png from '../../img/img/1920x400.png';

import { Link } from 'react-router-dom';

function HomePage() {
    return (
        <div>
            <img className='heder-img' src={Header_png} alt="" />

            <nav className='home-nav'>
                <ul>
                    <li><Link to="/page1">ページ１</Link></li>
                    <li><Link to="/page2">ページ２</Link></li>
                    <li><Link to="/page3">ページ３</Link></li>
                </ul>
            </nav>
        </div>
    )
}

export default HomePage