import React from 'react'

function E404() {
  return (
    <div className='e404'>
      <h1>Opps!:(</h1>
      <h3>This page does not seem to exist.</h3>
      <p>Error code is <u>404 not found</u>.</p>
    </div>
  )
}

export default E404