import React from 'react'

function Footer() {
  return (
    <div className='footer'>
      <p>© 2024 Site-Name</p>
    </div>
  )
}

export default Footer