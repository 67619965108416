import React from 'react'

import Header from './Home/Header';
import TopPage from './Home/ArticleList';
import Footer from './Footer';
import SearchBar from './Parts/SearchBar';

function HomePage() {
    return (
        <div>
            {/* ヘッダー */}
            <Header />

            {/* パンくずリスト */}
            <ul className='page-list'>
                <li>サイト名</li>
            </ul>

            {/* 検索バー */}
            <SearchBar />

            {/* トップページ */}
            <TopPage />

            {/* フッター */}
            <Footer />

        </div>
    )
}

export default HomePage