import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import cloud_svg from '../../img/svg/cloud.svg';

import Clock from '../../img/svg/Clock.svg';

function ArticleList() {
    const [data, setData] = useState({ contents: [] });
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const response = await fetch(process.env.REACT_APP_MINEBLOG_APP_API_ENDPOINT, {
                    headers: {
                        'X-API-KEY': process.env.REACT_APP_MINEBLOG_APP_API_KEY,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const fetchedData = await response.json();
                setData(fetchedData);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    if (isLoading) {
        return <div className='Loading'>
            <img className='cloud_svg' src={cloud_svg} alt="読み込みアニメーション" />
            <p>Now Loading...</p>
        </div>;
    }

    return (
        <div>
            <div className="article">
                <div className='article-text'>
                    {data.contents.map((item, index) => (
                        <div className='article-div' key={index}>
                            <Link to={`/ja-jp/home/blog/${item.id}`}>
                                <img className='article-img' src={item.thumbnail.url} alt="ブログサムネイル" />
                                <div className='date-clock'>
                                    <img className='clock' src={Clock} alt="" />
                                    <p className='date'>{formatDate(item.date)}</p>
                                </div>
                                <div dangerouslySetInnerHTML={{ __html: item.body.substring(0, 30) + '...' }} />
                                <p className='read-more'>もっと見る...</p>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default ArticleList;
