import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import Header from '../Home/Header';
import cloud_svg from '../../img/svg/cloud.svg';
import Footer from '../Footer';

function ArticlePage() {
    const { id } = useParams(); // URLからidを取得
    const [article, setArticle] = useState(null);
    const [isLoading, setIsLoading] = useState(true); // ローディング状態の追加

    useEffect(() => {
        const fetchArticle = async () => {
            setIsLoading(true); // データのフェッチが開始されたときにローディング状態をtrueに設定
            try {
                const response = await fetch(`${process.env.REACT_APP_MINEBLOG_APP_API_ENDPOINT}/${id}`, {
                    headers: {
                        'X-API-KEY': process.env.REACT_APP_MINEBLOG_APP_API_KEY,
                    },
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const fetchedArticle = await response.json();
                setArticle(fetchedArticle);
            } catch (error) {
                console.error('There has been a problem with your fetch operation:', error);
            } finally {
                setIsLoading(false); // データが取得されたらローディング状態をfalseに設定
            }
        };

        fetchArticle();
    }, [id]); // idが変わったときにもfetchを実行するために、依存配列にidを追加

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        return date.toLocaleDateString('ja-JP', { year: 'numeric', month: '2-digit', day: '2-digit' });
    };

    return (
        <>
            {/* ヘッダー */}
            <Header />

            {/* パンくずリスト */}
            <ul className='page-list'>
                <li><Link to="/">サイト名</Link></li>
                <p>{article ? article.title : ''}</p>
            </ul>

            <div className='ArticlePage'>
                {isLoading ? ( // ローディング状態に基づいて表示を制御
                    <div className='Loading'>
                        <img className='cloud_svg' src={cloud_svg} alt="読み込みアニメーション" />
                        <p>Now Loading...</p>
                    </div>
                ) : (
                    <div className='ArticlePage-text'>
                        <h1 className='article-title'>{article.title}</h1>
                        <p className='article-date'>{formatDate(article.date)}</p>
                        <div className='article-body' dangerouslySetInnerHTML={{ __html: article.body }} />
                        {/* 他の記事の詳細を表示するコンポーネント */}
                        <div className='back-to-home'>
                            <Link to="/">←ホームに戻る</Link>
                        </div>
                    </div>
                )}
            </div>

            {/* フッター */}
            <Footer />
        </>
    );
}

export default ArticlePage;
